import React, { Component } from 'react';
import collection from './images';

import * as styles from './interiors.module.scss';

class Interiors extends Component {
  render() {
    return (
      <section className={styles.interiorsSection}>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[0]} alt=""/>
          </div>
          <div>
            <img src={collection[1]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[2]} alt=""/>
          </div>
          <div>
            <img src={collection[3]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[4]} alt=""/>
          </div>
          <div>
            <img src={collection[5]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[6]} alt=""/>
          </div>
          <div>
            <img src={collection[7]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[8]} alt=""/>
          </div>
          <div>
            <img src={collection[9]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[10]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[11]} alt=""/>
          </div>
          <div>
            <img src={collection[12]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[13]} alt=""/>
          </div>
          <div>
            <img src={collection[14]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[15]} alt=""/>
          </div>
          <div>
            <img src={collection[16]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[17]} alt=""/>
          </div>
          <div>
            <img src={collection[18]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[19]} alt=""/>
          </div>
          <div>
            <img src={collection[20]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[21]} alt=""/>
          </div>
          <div>
            <img src={collection[22]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[23]} alt=""/>
          </div>
          <div>
            <img src={collection[24]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[25]} alt=""/>
          </div>
          <div>
            <img src={collection[26]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[27]} alt=""/>
          </div>
          <div>
            <img src={collection[28]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[29]} alt=""/>
          </div>
          <div>
            <img src={collection[30]} alt=""/>
          </div>
        </div>
        <div className={styles.interiorsRow}>
          <div>
            <img src={collection[31]} alt=""/>
          </div>
          <div>
            <img src={collection[32]} alt=""/>
          </div>
        </div>
      </section>
    );
  }
}


export default Interiors;
