import Image1 from './images/hotel-951599_1280.png';
import Image2 from './images/hotel-951599_1280.png';
import Image3 from './images/berlin-935960_1920.png'
import Image4 from './images/pool-1228999_1280.png'
import Image5 from './images/building-860923_1280.png'
import Image6 from './images/interior-1026456_1920.png'
import Image7 from './images/bathroom-713248_1280.png'
import Image8 from './images/sofa-184551_1280.png'
import Image9 from './images/bedroom-69570_1280.png'
import Image10 from './images/mandarin-oriental-condo-988955_1280.png'
import Image11 from './images/hotel-951398_1280.png'
import Image12 from './images/mandarn-oriental-condo-988952_1280.png'
import Image13 from './images/governors-mansion-1612734_1280.png'
import Image14 from './images/wall-416060_1280.png'
import Image15 from './images/aria-945224_1920.png'
import Image16 from './images/chandelier-1224248_1920.png'
import Image17 from './images/interior-1471317_1280.png'
import Image20 from './images/beach_hollywood.png'
import Image21 from './images/office-building-1026497_1280.png'
import Image22 from './images/interior-1026452_1920.png'
import Image23 from './images/hotel-95159980.png'
import Image24 from './images/hotel-951399_1280.png'
import Image25 from './images/interior-1026451_1920.png'
import Image26 from './images/interior-1026449_1920.png'
import Image27 from './images/room-416050_1280.png'
import Image28 from './images/bed-71280.png'
import Image29 from './images/hotel-lobby-362568_1280.png'
import Image30 from './images/poker.png'
import Image31 from './images/wall-416062_1280.png'
import Image32 from './images/hotel-389256_1280.png'
import Image33 from './images/fireplace-416042_1280.png'
import Image34 from './images/hotel-951600_1280.png'
import Image35 from './images/wall-416062_12f80.png'
import Image36 from './images/apartment-416039_1280.png'

export default [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,
  Image27,
  Image28,
  Image29,
  Image30,
  Image31,
  Image32,
  Image33,
  Image34,
  Image35,
  Image36
];
