import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Interiors from "../components/Interiors"

const InteriorsPage = () => (
  <Layout>
    <Seo title="Interiors" />
    <Interiors />
  </Layout>
)

export default InteriorsPage
